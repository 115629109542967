<template lang="pug">
  div
    div
      v-btn(
        v-if="model.result && btnResultLabel"
        @click="resultShowAndMarkSeen"
        small
        outlined
        color="primary"
        data-test="results-show") {{ btnResultLabel }}
    div
      v-btn(
        v-if="canLoadData || statusLoaded || statusFailed"
        :color="PURPLE"
        outlined
        @click="showModal"
        small
        data-test="results-set"
        :loading="model.loading"
      ).text-capitalize Set
    v-dialog(v-model="showing" max-width="500" scrollable)
      v-card(data-test="results-info-dialog")
        v-card-title.headline {{ model.candidate.name }} results:
        v-divider
        v-card-text(v-if="result" data-test="edit-form")
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content
              v-select(
                data-test="results-field"
                v-model="result.results"
                :items="options"
                label="Choose result"
                :error="formErrors.hasError('results')"
                :error-messages="formErrors.fields.results"
              )
          p.text-box
            span.text-box__title Categorie:
            span.text-box__content {{ model.productName }}
          p.text-box
            v-checkbox(
              v-model="result.has_zero_mistakes"
              label='Zero mistakes'
              hide-details
            )
          <!--h3.form-title Stats-->
          <!--p.text-box-->
            <!--span.text-box__title Onderdeel 1. Gevaarherkenning:-->
            <!--span.text-box__content-->
              <!--v-text-field(-->
                <!--data-test="theoretical-field"-->
                <!--v-model="result.theoretical"-->
                <!--type="number"-->
                <!--:error="formErrors.hasError('theoretical')"-->
                <!--:error-messages="formErrors.fields.theoretical"-->
              <!--)-->

          <!--p.text-box-->
            <!--span.text-box__title Onderdeel 2. Kennis:-->
            <!--span.text-box__content-->
              <!--v-text-field(-->
                <!--data-test="knowledge-field"-->
                <!--v-model="result.knowledge"-->
                <!--type="number"-->
                <!--:error="formErrors.hasError('knowledge')"-->
                <!--:error-messages="formErrors.fields.knowledge"-->
              <!--)-->

          <!--p.text-box-->
            <!--span.text-box__title Onderdeel 3. Inzicht:-->
            <!--span.text-box__content-->
              <!--v-text-field(-->
                <!--data-test="insight-field"-->
                <!--v-model="result.insight"-->
                <!--type="number"-->
                <!--:error="formErrors.hasError('insight')"-->
                <!--:error-messages="formErrors.fields.insight"-->
              <!--)-->

          h3.form-title(v-if="mistake") Mistake:
          p.text-box(v-if="mistake")
            span.text-box__content(v-html="mistake" )
        v-card-actions
          v-spacer
          v-btn(data-test="save-btn" color="primary" @click="saveResults") save
          v-btn(text color="primary" @click="hideModal") close


</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import resultMixins from '../core/mixins/result.mixin'
import ResultReservedExam, { ResultReservedExamFormValidationFields } from "../core/models/resultReservedExam";
import { RESULT_TYPES, PURPLE } from '../core/results-const'
import FormErrors from "@/util/form-errors"

export default {
  mixins: [errorsMixin, resultMixins],


  data: () => ({
    PURPLE,
    options: Object.values(RESULT_TYPES),
    result: null,
    formErrors: null,
  }),

  mounted() {
    this.init()
  },

  computed: {
    btnResultLabel() {
      if(!this.model.result || !this.model.result.results) return null
      return this.model.result.results[0].toUpperCase()
    },
    mistake() {
      if(this.statusInit) return null
      if(!this.model.result) return null
      return this.model.result.mistake.replace(/\n/g, '<br />')
    },
    canLoadData() {
      if(!this.model.result) return true
      return false
    },
  },

  watch: {
    'showing'(value) {
      if (value) {
        this.init()
      }
    }
  },

  methods: {
    init() {
      this.resetErrors()
      this.result = this.model.result ? new ResultReservedExam({...this.model.result, has_zero_mistakes: this.model.result.has_zero_mistakes}) : new ResultReservedExam({})
    },
    resetErrors() {
      this.formErrors = new FormErrors();
    },
    async saveResults() {
      try {
        this.model.loading = true
        await this.svc.saveResults(this.model.ID, this.result.getApiData())
        this.hideModal()
      } catch (error) {
        console.log(error, 'err')
        this.processErrorWithForm(error,
          {
            formErrors: this.formErrors,
            redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
            apiAttributes: new ResultReservedExamFormValidationFields()
          }
        )
      } finally {
        this.model.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .text-box {
    margin-top: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    &__content {
      margin-left: 8px;
      color: #000;
    }
  }
</style>
