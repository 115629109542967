import { RESULT_STATUS } from "../models/resultReservedExam"

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    statusInit: {
      type: Boolean,
      required: true
    },
  },

  inject: ['svc'],

  data: () => ({
    showing: false
  }),

  computed: {
      
    statusLoaded() {
      if(this.model.result && this.model.result.status === RESULT_STATUS.FETCHING) return true
      return false
    },
          
    statusFailed() {
      if(this.model.result && this.model.result.status === RESULT_STATUS.FAILED) return true
      return false
    }
  },

  methods: {
    resultShowAndMarkSeen() {
      this.showModal()
      this.markSeen()
    },
    async markSeen() {
      try {
        await this.svc.markSeen(this.model.ID)
        this.model.result.seen = true
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },
    showModal() {
      this.showing = true
    },
    hideModal() {
      this.showing = false
    },
  }

}